<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="products"
              :load-dictionaries="loadDictionaries"
              :show-labels="false"
              :filters="filteringPanel.filters"
              @search="getData"
              @change="filterData"
              :loaded="filteringPanel.loaded"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <products-table ref="products" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import ProductsTable from "@/views/SalesAdministrative/ProductsTable";

export default {
  name: "Products",
  components: {
    FilteringPanel,
    ProductsTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            tooltip: "Product name",
            title: "Product",
            name: "product",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Product category",
            title: "Product category",
            name: "product_category",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Finished product name",
            title: "Finished Product",
            name: "finished_product",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    async loadDictionaries() {
      let self = this;

      const products = async () => {
        let response = await this.$api.get("dictionaries/products");

        self.filteringPanel.filters.find(
          f => f.name === "product"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const categories = async () => {
        let response = await this.$api.get("dictionaries/products/categories");

        self.filteringPanel.filters.find(
          f => f.name === "product_category"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const finishedproducts = async () => {
        let response = await this.$api.get("products/finished-products");
        self.filteringPanel.filters.find(
          f => f.name === "finished_product"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      Promise.all([products(), categories(), finishedproducts()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    filterData: function(e) {
      //let self = this;

      this.filteringPanel.selected = e;
    },
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        products: f.product ? f.product.map(i => i.label) : [],
        product_categories: f.product_category
          ? f.product_category.map(i => i.label)
          : [],
        base_products: f.base_product ? f.base_product.map(i => i.label) : []
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["products"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style></style>
